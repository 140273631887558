<template>
  <div class="p-1">
    <b-row v-if="profile">
      <b-col cols="8" offset-md="2">
        <b-card no-body>
          <div class="part-container">
            <h2>{{profile.candidate.FirstName}} {{profile.candidate.LastName}}</h2>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <b-row>
              <b-col cols="3">
                <h4>{{i18nT(`Contact`)}}</h4>
              </b-col>
              <b-col cols="5">
                <span v-if="profile.candidate.Address1">{{profile.candidate.Address1}}, </span>
                <span v-if="profile.candidate.Address2">{{profile.candidate.Address2}}, </span>
                <span v-if="profile.candidate.City">{{profile.candidate.City}}, </span>
                <span v-if="profile.candidate.Country">{{countries[profile.candidate.Country]}}</span>
              </b-col>
              <b-col cols="4">
                <label>
                  <strong>Email:</strong> <b-link :href="`mailto:${profile.candidate.Email}`">{{profile.candidate.Email}}</b-link>
                </label><br>
                <label>
                  <strong>Web:</strong> <b-link :href="profile.candidate.Web">{{profile.candidate.Web}}</b-link>
                </label><br>
                <label>
                  <strong>Phones:</strong> <b-link>{{profile.candidate.Phones}}</b-link>
                </label>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`Work Experience`)}}</h4>
            <b-row
              v-for="(job, index) of profile.jobs"
              :key="`job-${index}`"
            >
              <b-col cols="3">
                <span>{{job.StartDate}} - {{job.EndDate}}</span>
              </b-col>
              <b-col cols="9">
                <span>
                  {{job.Company}}
                </span>
                <br>
                <span>
                  {{job.JobTitle}}
                </span>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`Education`)}}</h4>
            <b-row
              v-for="(education, index) of profile.education"
              :key="`education-${index}`"
            >
              <b-col cols="3">
                <span>{{education.StartDate}} - {{education.EndDate}}</span>
              </b-col>
              <b-col cols="9">
                <span>
                  <strong>{{education.Institution}}</strong>
                </span>
                <br>
                <span>
                  {{education.Course}}
                </span>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`Certificates`)}}</h4>
            <b-row
              v-for="(certificate, index) of profile.certificates"
              :key="`certificate-${index}`"
            >
              <b-col cols="3">
                <span>{{certificate.Label}}</span>
              </b-col>
              <b-col cols="9">
                <div v-if="certificate.AttachedFile">
                  <span>
                    {{certificate.Description}}
                  </span>
                  <br>
                  <b-link :href="certificate.AttachedFile.DownloadUrl">
                    {{certificate.AttachedFile.Label}}
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`Skills`)}}</h4>
            <b-row
              v-for="(skill, index) of profile.skills"
              :key="`skill-${index}`"
            >
              <b-col cols="3">
              </b-col>
              <b-col cols="9">
                <span>
                  <strong>{{skill.Skill}}:</strong> {{skillOptions[skill.LevelId]}}
                </span>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`Languages`)}}</h4>
            <b-row
              v-for="(lang, index) of profile.languages"
              :key="`lang-${index}`"
            >
              <b-col cols="3">
              </b-col>
              <b-col cols="9">
                <span>
                  <strong>{{lang.Language}} -</strong>   Write: <strong> {{languageOptions[lang.WriteId]}} </strong>  Speak: <strong> {{languageOptions[lang.SpeakId]}} </strong>
                </span>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`References`)}}</h4>
            <b-row
              v-for="(ref, index) of profile.references"
              :key="`skill-${index}`"
            >
              <b-col cols="3">
              </b-col>
              <b-col cols="9">
                <span>
                  <strong>{{ref.Label}}</strong><br>
                  {{ref.Description}}<br>
                </span>
                <div v-if="ref.AttachedFile">
                  <b-link :href="ref.AttachedFile.DownloadUrl">
                    {{ref.AttachedFile.Label}}
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </div>
          <hr class="invoice-spacing">
          <div class="part-container">
            <h4>{{i18nT(`Interests and other`)}}</h4>
            <b-row
              v-for="(interest, index) of profile.interests"
              :key="`interest-${index}`"
            >
              <b-col cols="3">
              </b-col>
              <b-col cols="9">
                <span>
                  <strong>{{interest.Label}}</strong><br>
                  {{interest.Description}}
                </span>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>      
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
  },
  data() {
    return {
      profile: null,
      skillOptions: {},
      languageOptions: {},
      countries: {},
      cvAvailability: [],
      switchOptions: [
        { text: 'CV is Public', value: 'public' },
      ],
      publicLink: ""
    }
  },
  created() {
    const components = this.$route.fullPath.split("_")
    const freelancerId = components[components.length - 1]
    this.$http.get(
      `candidates/publicCandidate?full=1&id=${freelancerId}`
    ).then(({data}) => {
      this.profile = data.data
      this.skillOptions = data.data.skill_levels
      this.languageOptions = data.data.language_levels
    })

    this.$http.get(
      `system/countries`
    ).then(({data}) => {
      this.countries = data.data
    })
  },
  methods: {
  },
}
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

.part-container {
  padding: 16px;
}

</style>
